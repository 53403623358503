import React from "react"
import { BLOCKS, MARKS } from "@contentful/rich-text-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { Text, Box } from "@chakra-ui/core"
import { Bold, Code, Italic, Underline } from "./Marks"
import Image from "./Image"
import OrderedList from "./OrderedList"
import UnorderedList from "./UnorderedList"
import ListItem from "./ListItem"
import { H1, H2, H3 } from "./Headings"

const options = {
  renderMark: {
    [MARKS.BOLD]: function BoldText(text) {
      return <Bold>{text}</Bold>
    },
    [MARKS.UNDERLINE]: function UnderlineText(text) {
      return <Underline>{text}</Underline>
    },
    [MARKS.ITALIC]: function ItalicText(text) {
      return <Italic>{text}</Italic>
    },
    [MARKS.CODE]: function CodeText(text) {
      return <Code>{text}</Code>
    },
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: function TextNode(_, children) {
      return <Text>{children}</Text>
    },
    [BLOCKS.HEADING_1]: function Heading1Node(_, children) {
      return <H1>{children}</H1>
    },
    [BLOCKS.HEADING_2]: function Heading2Node(_, children) {
      return <H2>{children}</H2>
    },
    [BLOCKS.HEADING_3]: function Heading3Node(_, children) {
      return <H3>{children}</H3>
    },
    [BLOCKS.UL_LIST]: function UnorderedListNode(_, children) {
      return <UnorderedList>{children}</UnorderedList>
    },
    [BLOCKS.OL_LIST]: function OrderedListNode(_, children) {
      return <OrderedList>{children}</OrderedList>
    },
    [BLOCKS.LIST_ITEM]: function ListItemNode(_, children) {
      return <ListItem>{children}</ListItem>
    },
    [BLOCKS.EMBEDDED_ASSET]: function ImageNode(data) {
      return (
        <Image
          alt={data.data.target.fields.description["en-US"]}
          src={data.data.target.fields.file["en-US"].url}
        />
      )
    },
    [BLOCKS.QUOTE]: function QuoteNode(_, children) {
      return (
        <Box
          as="blockquote"
          borderLeft="3px solid rgba(0, 0, 0, .07)"
          my={3}
          ml={3}
          pl={5}
        >
          {children}
        </Box>
      )
    },
  },
}

const renderer = content => documentToReactComponents(content, options)

export default renderer
