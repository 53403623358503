import React from "react"
import { Box, Heading, Flex, Stack, Button } from "@chakra-ui/core"
import { graphql } from "gatsby"
import { Link, FormattedMessage } from "gatsby-plugin-intl"

import documentToReactComponents from "../components/contentfulRendering"
import Layout from "../components/Layout"
import LatestNewsItem from "../components/LatestNewsItem"
import NewsPreview from "../components/NewsPreview"
import LightHoverButton from "../components/chakraOverrides/LightHoverButton"
import makeBackgroundImageArray from "../helpers/makeBackgroundImageArray"

const BlogPost = props => {
  return (
    <Layout location={props.location} bg={makeBackgroundImageArray("news")}>
      <Button
        variant="ghost"
        variantColor="teal"
        as={Link}
        to="/"
        mb={4}
        mx={4}
      >
        <FormattedMessage id="news.back" />
      </Button>
      <Box pt={12} maxW="800px" mt={3} mb={20} mx={4}>
        <Heading fontSize="6xl" as="h1" fontWeight="bold">
          {props.data.contentfulBlogPost.headline}
        </Heading>
      </Box>
      <Box my={8} mx={4}>
        {documentToReactComponents(props.data.contentfulBlogPost.content.json)}
      </Box>
      <Box my={8} mx={4}>
        <Heading fontSize="4xl" fontWeight="bold" as="h2" my={5}>
          <FormattedMessage id="homepage.news" />
        </Heading>
        <Flex direction="row" wrap={["wrap", null, null, "nowrap"]}>
          <Stack mr={4}>
            {props.data.allContentfulBlogPost.edges
              .map(edge => edge.node)
              .map(node => (
                <NewsPreview
                  author={node.author}
                  imageFixed={node.image.fixed}
                  headline={node.headline}
                  excerpt={node.excerpt.content}
                  slug={node.slug}
                />
              ))}
          </Stack>
          <Box
            minW={300}
            maxW={["100%", null, null, "25%"]}
            w="100%"
            my={[4, null, null, 0]}
          >
            <Heading fontSize="2xl" fontWeight="bold" mb={3}>
              <FormattedMessage id="homepage.latest_news" />
            </Heading>
            <Stack spacing={2} w="100%">
              {props.data.allContentfulBlogPost.edges
                .map(edge => edge.node)
                .map(node => (
                  <LatestNewsItem text={node.headline} date={node.createdAt} />
                ))}
            </Stack>
            <LightHoverButton width="100%" my={4}>
              <FormattedMessage id="homepage.all_news" />
            </LightHoverButton>
          </Box>
        </Flex>
      </Box>
    </Layout>
  )
}

export const query = graphql`
  query BlogPost($slug: String!) {
    contentfulBlogPost(slug: { eq: $slug }) {
      id
      headline
      content {
        json
      }
    }
    stockImage: file(relativePath: { eq: "stock.jpg" }) {
      childImageSharp {
        fixed(width: 350, height: 240, cropFocus: CENTER) {
          ...GatsbyImageSharpFixed_tracedSVG
        }
        fluid {
          ...GatsbyImageSharpFluid_tracedSVG
        }
      }
    }
    allContentfulBlogPost(sort: { fields: createdAt, order: DESC }, limit: 5) {
      edges {
        node {
          slug
          headline
          author
          createdAt
          excerpt {
            content: excerpt
          }
          image {
            fixed(height: 240, width: 350, cropFocus: CENTER) {
              ...GatsbyContentfulFixed
            }
          }
        }
      }
    }
  }
`

export default BlogPost
